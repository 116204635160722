@font-face {
  font-family: 'Frutiger';
  src: url('/assets/fonts/frutiger_lt_std_45_light.woff2') format('woff2');
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger';
  src: url('/assets/fonts/frutiger_lt_std_65_bold.woff2') format('woff2');
  font-weight: bold;
  font-display: swap;
}

//@import '@material-design-icons/font';

