@mixin scrollbar($thumb-color: var(--scrollbar-thumb, var(--sps-color-blue)),$bg-color: var(--scrollbar-bg, white), $border-color: var(--scrollbar-border-color, var(--sps-color-blue)), $bar-height: 5px) {

  $bar-spacing: 5px;
  $bar-width: 10px + $bar-spacing;

  &::-webkit-scrollbar {
    height: $bar-height;
    width: $bar-width;
    background-color: $bg-color;
    border: 5px solid $bg-color;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border: $bar-spacing solid $bg-color;
  }
}
