@use 'mixins/typo.mixin' as *;

input, textarea, fake-input {
  font-family: var(--sps-font-family);
  appearance: none;
  font-size: 1rem;
  padding: 0;

  &:focus, &:active {
    outline: 0;
    box-shadow: none;
  }
}

sps-text-input, sps-select-box, sps-checkbox, sps-email-input {
  --border-color: var(--sps-border-color-darker);
  --border-width: 1px;

  &:has(.error) {
    --border-color: var(--sps-color-red);
    --border-width: 2px;
  }

  label {
    @include typo(small);
    color: var(--sps-text-color-med);
    height: var(--sps-line-height-small);
    display: block;
    margin-bottom: 0.5em;
  }

  input:not([type="checkbox"]), select, .fake-input {
    font-family: var(--sps-font-size);
    @include typo(form);

    appearance: none;
    border: var(--border-width) solid var(--border-color);
    border-radius: var(--sps-border-radius);
    padding: 0.75em 1em;
    box-sizing: border-box;
    width: 100%;

    &:hover, &:focus, &:active {
      --border-color: var(--sps-color-blue);
      outline: 0;
      box-shadow: none;
    }
  }
}

// Honeypot field
sps-text-input[data-id="-1"] {
  opacity: 0;
  position: absolute;
  left: -100vw;
}
