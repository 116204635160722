@use 'sass:math' as math;

// Rawnet definitions
$font-sizes: (
  body: 18px,
  small: 12px,
  button: 14px,
  button-med: 16px,
  tag-large: 12px,
  tag-regular: 10px,
  mega: 74px,
  giga: 56px,
  form: 15px,
  h1: 42px,
  h2: 36px,
  h3: 28px,
  h4: 22px,
  h5: 14px,
);

// Rawnet definitions
$line-heights: (
  body: 26px,
  small: 18px,
  button: 18px,
  button-med: 18px,
  tag-large: 14px,
  tag-regular: 14px,
  mega: 76px,
  giga: 58px,
  form: 22px,
  h1: 50px,
  h2: 44px,
  h3: 36px,
  h4: 28px,
  h5: 22px,
);

$headlines: (h1, h2, h3, h4, h5);


:root{
  $sps-font-size: 18px;
  --sps-font-size: #{$sps-font-size};

  @each $key, $font-size in $font-sizes {
    $rem-value: math.div($font-size, $sps-font-size);
    --sps-font-size-#{$key}: calc(var(--sps-typo-scale-#{$key}, 1) * #{$rem-value}rem);

    $line-height: map-get($line-heights, $key);
    $rem-value: math.div($line-height, $sps-font-size);

    --sps-line-height-#{$key}: calc(var(--sps-typo-scale-#{$key}, 1) * #{$rem-value}rem);
  }

}

@each $selector in $headlines {
  #{$selector}, .#{$selector} {
    font-size: var(--sps-font-size-#{$selector});
    line-height: var(--sps-line-height-#{$selector});
  }
}
