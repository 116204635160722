a {
  &, &:hover {
    text-decoration: none;
  }
}

button.raw {
  appearance: none;
  outline: 0;
  box-sizing: border-box;
  background-color: var(--button-bg-color, transparent);
  font-family: var(--sps-font-family);
  border: 2px inset transparent;

  &:focus {
    outline: initial;
    border-color: var(--sps-color-dark-blue);
  }
}

img.sps-image {
  --object-fit: cover;
  transition: opacity var(--sps-default-duration) linear;
  object-fit: var(--object-fit);
}
//
//img[srcset] {
//  transition: opacity var(--sps-default-duration) linear;
//
//  &:not(.loaded) {
//    opacity: 0 !important;
//  }
//}

div#custom-gpt {
  margin-top: calc(2 * var(--sps-default-spacing));
  margin-bottom: calc(2 * var(--sps-default-spacing));
}

*[data-lazyloaded] {
  opacity: 0;
  transition: opacity var(--sps-default-duration) ease-out;

  &.lazy-loaded {
    opacity: 1;
  }
}
